import React, { useEffect, useState } from 'react';
import './App.css';
import {MapContainer, Marker, Polyline, Popup, TileLayer} from "react-leaflet";
import {Container} from "react-bootstrap";
import {getAllPlaces, getAllSupplyChains, Place, SupplyChain} from "./database";

const App = () => {
    const [places, setPlaces] = useState<Map<string, Place>>(new Map<string, Place>());
    const [supplyChains, setSupplyChains] = useState<Map<string, SupplyChain>>(new Map<string, SupplyChain>());
    
    useEffect(() => {
        getAllPlaces()
            .then((places) => {
                setPlaces(places)
            })
            .then(getAllSupplyChains)
            .then((supplyChains) => {
                setSupplyChains(supplyChains)
            })
    }, [])
    
    return (
        <Container className="p-3">
            <Container className="p-5 mb-4 bg-light rounded-3">
                <h1 className="header">transparensa Map</h1>
                <MapContainer center={[49.0118, 8.3825]} zoom={13} scrollWheelZoom={true} style={{"height": "800px"}}>
                    <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                    {[...places].map(([id, place]) =>
                        <Marker key={id} position={place.coordinates}>
                            <Popup>
                                {place.name}<br/>
                                {place.street} {place.houseNumber}<br/>
                                {place.postalCode} {place.city}<br/>
                                {place.country}
                            </Popup>
                        </Marker>
                    )}
                    {[...supplyChains].map(([id, supplyChain]) =>
                        <Polyline positions={supplyChain.getCoordinateList(places)} key={id}>
                            <Popup>
                                {supplyChain.foods.join(", ")}
                            </Popup>
                        </Polyline>
                    )}
                </MapContainer>
            </Container>
        </Container>
    )
}

export default App;
