import { initializeApp } from 'firebase/app';
import { collection, FirestoreDataConverter, GeoPoint, getDocs, getFirestore, query } from 'firebase/firestore';
import {LatLng} from "leaflet";

// note: this data is not confidential
const firebaseConfig = {
    apiKey: "AIzaSyAx2qA02-YQ9c4OybXkM_KWwzipFritboE",
    authDomain: "transparensa.firebaseapp.com",
    projectId: "transparensa",
    storageBucket: "transparensa.appspot.com",
    messagingSenderId: "311283403629",
    appId: "1:311283403629:web:a12f4d8f36f84ca74de864"
};

const LOCATION_COLLECTION_NAME = "places";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export class Place {
    constructor(
        readonly name: string,
        readonly street: string,
        readonly houseNumber: string,
        readonly postalCode: string,
        readonly city: string,
        readonly country: string,
        readonly countryCode: string,
        readonly coordinates: LatLng,
        readonly type: string,
    ) { }
}

export class SupplyChain {
    constructor(
        readonly canteenId: string,
        readonly supplierId: string,
        readonly manufacturerId: string | null,
        readonly foods: string[],
    ) { }

    getCoordinateList(places: Map<string, Place>): LatLng[] {
        const result = [];
        for (const placeId of [this.canteenId, this.supplierId, this.manufacturerId]) {
            if (placeId !== null && places.has(placeId)) {
                result.push(places.get(placeId)!.coordinates);
            }
        }
        return result;
    }
}

const locationConverter: FirestoreDataConverter<Place> = {
    toFirestore: (location: Place) => {
        return {
            name: location.name,
            street: location.street,
            house_number: location.houseNumber,
            post_code: location.postalCode,
            city: location.city,
            country: location.country,
            country_code: location.countryCode,
            coordinates: new GeoPoint(location.coordinates.lat, location.coordinates.lng),
            type: location.type,
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        const coordinates = data.coordinates as GeoPoint;
        return new Place(data.name, data.street, data.house_number, data.post_code, data.city, data.country,
            data.country_code, new LatLng(coordinates.latitude, coordinates.longitude), data.type);
    }
}

const supplyChainConverter: FirestoreDataConverter<SupplyChain> = {
    toFirestore: (supplyChain: SupplyChain) => {
        const obj = {
            canteen: supplyChain.canteenId,
            supplier: supplyChain.supplierId,
            foods: supplyChain.foods,
        } as any
        if (supplyChain.manufacturerId) {
            obj['manufacturer'] = supplyChain.manufacturerId;
        }
        return obj;
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new SupplyChain(data.canteen, data.supplier, data.manufacturer ? data.manufacturer : null,
            data.foods);
    }
}

export const getAllPlaces = async () => {
    const q = query(collection(db, LOCATION_COLLECTION_NAME)).withConverter(locationConverter);
    return await getDocs(q).then(snapshot => {
        return new Map(snapshot.docs.map(doc => [doc.id, doc.data()]));
    });
}

export const getAllSupplyChains = async () => {
    const q = query(collection(db, "supplyChains")).withConverter(supplyChainConverter);
    return await getDocs(q).then(snapshot => {
        return new Map(snapshot.docs.map(doc => [doc.id, doc.data()]));
    });
}
